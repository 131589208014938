'use client';

import { createTheme } from '@mui/material/styles';
import breakpoints from './breakpoints';
import colorTheme from './colors';
import typography from './typography';

const theme = createTheme({
  breakpoints,
  typography,
  palette: {
    ...colorTheme,
  },
  shadows: '0px 1px 4px rgba(80, 76, 64, 0.2)',
  spacing: 8,
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'none' },
          style: {
            textTransform: 'none',
            border: 'none',
            '&:hover': {
              textTransform: 'none',
              border: 'none',
            },
          },
        },
      ],
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          marginBottom: '8px!important',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&:before': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          // Controls default (unchecked) color for the thumb
          color: '#E7E7E7',
        },
        colorPrimary: {
          '&.Mui-checked': {
            // Controls checked color for the thumb
            color: '#002A3A',
          },
        },
        track: {
          // Controls default (unchecked) color for the track
          backgroundColor: '#fff',
          opacity: 1,
          '.Mui-checked.Mui-checked + &': {
            opacity: 1,
            // Controls checked color for the track
            backgroundColor: '#fff',
          },
        },
      },
    },
  },
});

export default theme;
