"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = validInput;
var _emailVerification = _interopRequireDefault(require("./emailVerification"));
var _nameVerification = _interopRequireDefault(require("./nameVerification"));
var _phoneVerification = _interopRequireDefault(require("./phoneVerification"));
var _addressVerification = require("./addressVerification");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function validInput(input, inputType, country) {
  switch (inputType) {
    case 'email':
      return (0, _emailVerification["default"])(input);
    case 'name':
      return (0, _nameVerification["default"])(input);
    case 'phone':
      return (0, _phoneVerification["default"])(input);
    case 'address':
      return (0, _addressVerification.validAddress)(input);
    case 'city':
      return (0, _addressVerification.validCity)(input);
    case 'postcode':
      return (0, _addressVerification.validPostcode)(input, country);
    case 'country':
      return (0, _addressVerification.validCountry)(input);
    default:
      return false;
  }
}