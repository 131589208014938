const primaryColors = {
  dark: '#002A3A',
  light: '#F9F7F2',
  lightWeb: '#F1EEE6',
  warmGrey: '#666562',
  white: '#FFFFFF',
};

const colorTheme = {
  // primary brand colors
  primaryColors,

  // background
  backgroundPrimaryDark: primaryColors.dark,
  backgroundPrimaryLightApp: primaryColors.light,
  backgroundPrimaryLightDark: primaryColors.light,
  backgroundPrimaryLightWeb: primaryColors.lightWeb,
  backgroundSecondaryLight: primaryColors.white,
  backgroundSecondaryDark: primaryColors.warmGrey,

  // container
  containerPrimaryOnLight: primaryColors.dark,
  containerSecondaryOnLight: '#EDF0F1',
  containerPrimaryOnDark: primaryColors.white,
  containerDisabled: '#E7E7E7',
  containerAccent: '#0A89FF',
  containerAccentDark: '#84C4FF',
  containerAccentLight: '#84C4FF',

  // content
  contentPrimaryOnLight: primaryColors.dark,
  contentPrimaryOnLightWeb: primaryColors.light,
  contentSecondaryOnLight: '#405F6B',
  contentSecondaryOnLightWeb: primaryColors.warmGrey,
  contentPrimaryOnDark: primaryColors.lightWeb,
  contentPrimaryWhite: primaryColors.white,
  contentActionOnLight: '#0A89FF',
  contentDisabled: '#5F6769',
  contentDisabled42: '#002A3A6B',

  // functional
  successDark: '#5AB560',
  successLight: '#DAF5DB',
  errorDark: '#EC4D6B',
  errorLight: '#FFEDF3',
  errorBright: '#FF032F',

  // stroke
  strokePrimaryOnLightDark: primaryColors.dark,
  strokePrimaryOnLightLight: '#CCD4D8',
  strokePrimaryOnDark: '#F0EDE5',
  strokeError: '#EC4D6B',
  strokeDisabledOnLight: '#5F6769',
  strokeFocus: '#0A89FF',

  // stream
  streamSteady: '#4EBFFF',
  streamRisingSlow: '#940DFF',
  streamRisingFast: '#F19653',

  // overlays
  onLightHovered: '#3d4c50',
  onLightFocused: '#3d4c50',
  onLightPressed: '#A0AFB5',
  onLightDisabled: '#a8a69f',
  onDarkHovered: '#e5e5e0',
  onDarkFocused: '#e5e5e0',
  onDarkPressed: '#dadbd7',
  onDarkDisabled: '#c7cfd0',

  // overlays + blur
  onLightBlur: '#3d4c50',
  onDarkBlur: '#565656',

  // modal
  modal: '#002A3A80', // opacity 80%

  buttonFocusBorder: '#006590',
};

export default {
  colorTheme,
};
