"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UNSUBSCRIBED = exports.SUBSCRIBED = exports.OPTED_IN = void 0;
var UNSUBSCRIBED = 'unsubscribed';
exports.UNSUBSCRIBED = UNSUBSCRIBED;
var SUBSCRIBED = 'subscribed';
exports.SUBSCRIBED = SUBSCRIBED;
var OPTED_IN = 'opted_in';
exports.OPTED_IN = OPTED_IN;