// Lingo Design System 4.0 (Previous Version)

const typography = {
  fontFamily: [
    'MatterRegular', // weight 400
    'MatterMedium', // weight 500
    'MatterSemibold', // weight 600
  ].join(','),
  h0regular: {
    // first sizes desktop
    fontSize: '35px',
    fontFamily: 'MatterRegular, Arial, sans-serif',
    lineHeight: '45px',
    '@media (max-width:820px)': {
      // these sizes small tablet/mobile
      fontSize: '30px',
      lineHeight: '40px',
    },
  },
  h0medium: {
    fontSize: '35px',
    fontFamily: 'MatterMedium, Arial, sans-serif',
    fontWeight: '500',
    lineHeight: '40px',
    '@media (max-width:820px)': {
      fontSize: '30px',
      lineHeight: '40px',
    },
  },
  h1: {
    fontSize: '35px',
    fontFamily: 'MatterSemibold, Arial, sans-serif',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '45px',
    letterSpacing: '-1.05px',
    '@media (max-width:820px)': {
      fontSize: '30px',
      lineHeight: '45px',
      letterSpacing: '-.6px',
    },
  },
  h2: {
    fontSize: '30px',
    fontFamily: 'MatterSemibold, Arial, sans-serif',
    fontWeight: '600',
    lineHeight: '45px',
    '@media (max-width:820px)': {
      fontSize: '28px',
    },
  },
  h3: {
    fontSize: '28px',
    fontFamily: 'MatterSemibold, Arial, sans-serif',
    fontWeight: '600',
    lineHeight: '40px',
    '@media (max-width:820px)': {
      fontSize: '24px',
      lineHeight: '32px',
    },
  },
  h4: {
    fontSize: '18px',
    fontFamily: 'MatterMedium, Arial, sans-serif',
    fontWeight: '500',
    lineHeight: '24px',
    '@media (max-width:820px)': {
      fontSize: '14px',
      lineHeight: '18px',
    },
  },
  h5: {
    fontSize: '12px',
    fontFamily: 'MatterSemibold, Arial, sans-serif',
    fontWeight: '600',
    lineHeight: '16px',
    '@media (max-width:820px)': {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  bodyL: {
    fontSize: '25px',
    fontFamily: 'MatterRegular, Arial, sans-serif',

    lineHeight: '35px',
    '@media (max-width:820px)': {
      fontSize: '20px',
      lineHeight: '30px',
    },
  },
  bodyBlogL: {
    fontSize: '22px',
    fontFamily: 'MatterRegular, Arial, sans-serif',

    lineHeight: '35px',
    '@media (max-width:820px)': {
      fontSize: '20px',
      lineHeight: '30px',
    },
  },
  bodyLMedium: {
    fontSize: '25px',
    fontFamily: 'MatterMedium, Arial, sans-serif',
    fontWeight: '500',
    lineHeight: '35px',
    '@media (max-width:820px)': {
      fontSize: '20px',
      lineHeight: '30px',
    },
  },
  bodyM: {
    fontSize: '16px',
    fontFamily: 'MatterRegular, Arial, sans-serif',
    lineHeight: '26px',
    letterSpacing: '-0.32px',
    '@media (max-width:820px)': {
      fontSize: '15px',
      lineHeight: '22px',
      letterSpacing: '-0.3px',
    },
  },
  bodyMLink: {
    fontSize: '16px',
    fontFamily: 'MatterRegular, Arial, sans-serif',

    lineHeight: '26px',
    '@media (max-width:820px)': {
      fontSize: '15px',
      lineHeight: '22px',
    },
  },
  bodyMSemibold: {
    fontSize: '16px',
    fontFamily: 'MatterSemibold, Arial, sans-serif',
    fontWeight: '600',
    lineHeight: '26px',
    letterSpacing: '-0.32px',
    '@media (max-width:820px)': {
      fontSize: '15px',
      lineHeight: '22px',
      letterSpacing: '-0.3px',
    },
  },
  bodyS: {
    fontSize: '14px',
    fontFamily: 'MatterRegular, Arial, sans-serif',

    lineHeight: '20px',
    '@media (max-width:820px)': {
      fontSize: '12px',
      lineHeight: '18px',
    },
  },
  bodySSemibold: {
    fontSize: '14px',
    fontFamily: 'MatterMedium, Arial, sans-serif',
    fontWeight: '500',
    lineHeight: '20px',
    '@media (max-width:820px)': {
      fontSize: '12px',
      lineHeight: '18px',
    },
  },
  bodySLink: {
    fontSize: '14px',
    fontFamily: 'MatterRegular, Arial, sans-serif',
    lineHeight: '20px',
    textDecoration: 'underline',
    '@media (max-width:820px)': {
      fontSize: '12px',
      lineHeight: '18px',
    },
  },
  bodyXSLink: {
    fontSize: '12px',
    fontFamily: 'MatterRegular, Arial, sans-serif',
    lineHeight: '18px',
    textDecoration: 'underline',
    '@media (max-width:820px)': {
      fontSize: '11px',
      lineHeight: '16px',
    },
  },
  bodyXS: {
    fontSize: '12px',
    fontFamily: 'MatterRegular, Arial, sans-serif',

    lineHeight: '18px',
    '@media (max-width:820px)': {
      fontSize: '11px',
      lineHeight: '16px',
    },
  },
  buttonL: {
    fontSize: '16px',
    fontFamily: 'MatterRegular, Arial, sans-serif',

    lineHeight: '24px',
  },
  buttonM: {
    fontSize: '16px',
    fontFamily: 'MatterSemibold, Arial, sans-serif',
    fontWeight: '600',
    lineHeight: '26px',
  },
  buttonS: {
    fontSize: '12px',
    fontFamily: 'MatterMedium, Arial, sans-serif',
    fontWeight: '500',
    lineHeight: '16px',
    textTransform: 'none',
  },
  linkL: {
    fontSize: '16px',
    fontFamily: 'MatterMedium, Arial, sans-serif',
    fontWeight: '500',
    lineHeight: '24px',
    textDecoration: 'underline',
  },
  linkM: {
    fontSize: '14x',
    fontFamily: 'MatterMedium, Arial, sans-serif',
    fontWeight: '500',
    lineHeight: '16px',
    textDecoration: 'underline',
  },
  linkS: {
    fontSize: '12px',
    fontFamily: 'MatterMedium, Arial, sans-serif',
    fontWeight: '500',
    lineHeight: '16px',
    textDecoration: 'underline',
  },
  dataXL: {
    fontSize: '120px',
    fontFamily: 'MatterRegular, Arial, sans-serif',

    lineHeight: '100px',
    '@media (max-width:820px)': {
      fontSize: '96px',
      lineHeight: '100px',
    },
  },
  dataL: {
    fontSize: '50px',
    fontFamily: 'MatterMedium, Arial, sans-serif',
    fontWeight: '500',
    lineHeight: '72px',
  },
  dataM: {
    fontSize: '16px',
    fontFamily: 'MatterMedium, Arial, sans-serif',
    fontWeight: '500',
    lineHeight: '24px',
  },
  dataS: {
    fontSize: '14px',
    fontFamily: 'MatterMedium, Arial, sans-serif',
    fontWeight: '500',
    lineHeight: '16px',
  },
  dataXS: {
    fontSize: '12px',
    fontFamily: 'MatterMedium, Arial, sans-serif',
    fontWeight: '500',
    lineHeight: '16px',
  },
  extraLarge: {
    fontSize: '80px',
    fontFamily: 'MatterRegular',
    '@media (max-width:820px)': {
      fontSize: '40px',
    },
  },
  heroText: {
    fontSize: '1.875rem',
    fontFamily: 'MatterRegular',
    fontWeight: '400',
    lineHeight: '2.5rem',
  },
  bodyLWeb3: {
    fontSize: '20px',
    fontFamily: 'MatterRegular, Arial, sans-serif',
    lineHeight: '30px',
    fontWeight: '400',
  },
  bodyMWeb3: {
    fontSize: '15px',
    fontFamily: 'MatterSemibold, Arial, sans-serif',
    lineHeight: '22px',
    fontWeight: '400',

    '@media (max-width:820px)': {
      fontFamily: 'MatterRegular, Arial, sans-serif',
      fontSize: '15px',
      lineHeight: '22px',
    },
  },
  quoteTestimonial: {
    fontFamily: 'MatterLight, Arial, sans-serif',
    fontSize: '16px',
    fontStyle: 'normal',
    lineHeight: '26px',
    letterSpacing: '-0.32px',
  },
  useNextVariants: true,
};
export default typography;
