"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GEOLOCATION_USA = exports.GEOLOCATION_NOT_ALLOWED = exports.GEOLOCATION_COMING_SOON = exports.GEOLOCATION_ALLOWED = void 0;
var GEOLOCATION_COMING_SOON = 'coming-soon';
exports.GEOLOCATION_COMING_SOON = GEOLOCATION_COMING_SOON;
var GEOLOCATION_ALLOWED = 'allowed';
exports.GEOLOCATION_ALLOWED = GEOLOCATION_ALLOWED;
var GEOLOCATION_NOT_ALLOWED = 'not-allowed';
exports.GEOLOCATION_NOT_ALLOWED = GEOLOCATION_NOT_ALLOWED;
var GEOLOCATION_USA = 'usa';
exports.GEOLOCATION_USA = GEOLOCATION_USA;