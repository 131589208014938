"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SENSOR = exports.PRODUCT_ID_PHYSICAL = exports.GB_CURRENCY = void 0;
var PRODUCT_ID_PHYSICAL = 'physical';
exports.PRODUCT_ID_PHYSICAL = PRODUCT_ID_PHYSICAL;
var SENSOR = 'sensor';
exports.SENSOR = SENSOR;
var GB_CURRENCY = 'GBP';
exports.GB_CURRENCY = GB_CURRENCY;