"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BRAZE_TOPIC = exports.BRAZE_SIGNUP_SOURCE = exports.BRAZE_RECIPE_MODAL = exports.BRAZE_OUT_OF_MARKET_MODAL = exports.BRAZE_NO_PURCHASE_MODAL = exports.BRAZE_MARKETING_LANDING = exports.BRAZE_CHECKOUT = exports.BRAZE_BLOG_FOOTER = exports.BRAZE_BLOG = exports.BRAZE_BENEFITS_QUIZ_FOOTER = exports.BRAZE_BENEFITS_QUIZ = void 0;
var BRAZE_SIGNUP_SOURCE = 'signup_source';
exports.BRAZE_SIGNUP_SOURCE = BRAZE_SIGNUP_SOURCE;
var BRAZE_OUT_OF_MARKET_MODAL = 'out_of_market_modal';
exports.BRAZE_OUT_OF_MARKET_MODAL = BRAZE_OUT_OF_MARKET_MODAL;
var BRAZE_NO_PURCHASE_MODAL = 'site_footer';
exports.BRAZE_NO_PURCHASE_MODAL = BRAZE_NO_PURCHASE_MODAL;
var BRAZE_RECIPE_MODAL = 'metabolism101_modal';
exports.BRAZE_RECIPE_MODAL = BRAZE_RECIPE_MODAL;
var BRAZE_CHECKOUT = 'checkout';
exports.BRAZE_CHECKOUT = BRAZE_CHECKOUT;
var BRAZE_BLOG = 'blog';
exports.BRAZE_BLOG = BRAZE_BLOG;
var BRAZE_TOPIC = 'topic';
exports.BRAZE_TOPIC = BRAZE_TOPIC;
var BRAZE_BLOG_FOOTER = 'blog_footer';
exports.BRAZE_BLOG_FOOTER = BRAZE_BLOG_FOOTER;
var BRAZE_BENEFITS_QUIZ = 'benefits_quiz';
exports.BRAZE_BENEFITS_QUIZ = BRAZE_BENEFITS_QUIZ;
var BRAZE_BENEFITS_QUIZ_FOOTER = 'benefits_quiz_footer';
exports.BRAZE_BENEFITS_QUIZ_FOOTER = BRAZE_BENEFITS_QUIZ_FOOTER;
var BRAZE_MARKETING_LANDING = 'marketing_landing';
exports.BRAZE_MARKETING_LANDING = BRAZE_MARKETING_LANDING;