"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = validName;
var _inputLimits = _interopRequireDefault(require("./inputLimits"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
// check that name does not just contain whitespace
function validName(name) {
  return name.replace(/\s/g, '').length > 0 && name.length <= _inputLimits["default"].name;
}