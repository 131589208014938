"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.decodeBasicAuthentication = decodeBasicAuthentication;
exports.encodeBasicAuthentication = encodeBasicAuthentication;
function encodeBasicAuthentication(username, password) {
  return "Basic ".concat(Buffer.from("".concat(username, ":").concat(password)).toString('base64'));
}
function decodeBasicAuthentication(authenticationHeader) {
  var authentication = Buffer.from(authenticationHeader.split(' ')[1], 'base64').toString().split(':');
  return {
    username: authentication[0],
    password: authentication[1]
  };
}