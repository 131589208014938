"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _ffReactClientSdk = require("@harnessio/ff-react-client-sdk");
var getGooglePayFlag = function getGooglePayFlag() {
  return (0, _ffReactClientSdk.useFeatureFlag)('DTC_GooglePay', false);
};
var _default = getGooglePayFlag;
exports["default"] = _default;