"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = splitAddress;
/*
 Split address functions.
 src: https://gist.github.com/fitnr/5818825
*/

function isNumber(n) {
  return !Number.isNaN(parseFloat(n)) && Number.isFinite(parseFloat(n));
}

// Check if character is a fraction, e.g. ¼
function isFractionalChar(n) {
  var c = n.charCodeAt();
  return c >= 188 && c <= 190 || c >= 8531 && c <= 8542;
}

// return the first fractional character in a string
// return false if there is none
// Could easily return the index of the character, but this creates a parallelism with RegExp.exec
function indexFractionalChar(m) {
  var a = m.split('');
  var i;
  // eslint-disable-next-line no-restricted-syntax
  for (i in a) {
    if (isFractionalChar(a[i])) return i;
  }
  return false;
}

/**
 * Splits an address into the number and street part.
 * with input: "100 Main Street", outputs: {number: "100", space: ' ', street: "Main Street"}
 * The special sauce is handling fractional addresses.
 * With input "22½ Baker Street", outputs: {number: "22½", space: ' ', street: "Baker Street"}
 *
 * @param string x An address with leading number
 * @return Object An object with the number, street and a space, for inserting between.
 * The space parameter is useful for situations where you want to glue the pieces back together for a user.
 * If user inputs "Main Street", without a number, .space is returned empty, so you don't have to bother testing
 * and just glue it like: x.number + x.space + x.street
 * while processing x.number and x.street separately on the back end.
 */
function splitAddress(x) {
  var a = x.trim().split(' ');
  var number;
  if (a.length <= 1) return {
    number: '',
    space: '',
    street: a.join('')
  };
  if (isNumber(a[0].substr(0, 1)) || isFractionalChar(a[0].substr(0, 1))) {
    number = a.shift();
  } else {
    // If there isn't a leading number, just return the trimmed input as the street
    return {
      number: '',
      space: '',
      street: x.trim()
    };
  }
  if (/[0-9]\/[0-9]/.exec(a[0]) || indexFractionalChar(a[0]) !== false) number += " ".concat(a.shift());
  return {
    number: number,
    space: ' ',
    street: a.join(' ')
  };
}