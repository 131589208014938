"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = formatPostcode;
function formatPostcode(countryCode, postcode) {
  if (countryCode === 'GB' || countryCode === 'XI') {
    var trimmedPostcode = postcode.trim();
    if (!trimmedPostcode.includes(' ')) {
      return "".concat(trimmedPostcode.slice(0, -3), " ").concat(trimmedPostcode.slice(-3));
    }
  }
  return postcode;
}