// src/logger.ts
import pino from "pino";
var pinoLogger;
var logger_default = pino({
  level: "info"
});
function getLogger(correlationId) {
  if (pinoLogger == null) {
    pinoLogger = pino({
      level: "info"
    });
  }
  if (correlationId == null) {
    return pinoLogger;
  }
  return pinoLogger.child({ correlationId });
}

// src/getCorrelationId.ts
import { v4 as UUIDv4 } from "uuid";
function getCorrelationId(headers) {
  return headers?.get("x-correlation-id") ?? UUIDv4();
}

// src/index.ts
var src_default = logger_default;
export {
  src_default as default,
  getCorrelationId,
  getLogger
};
