const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1440,
    fhd: 1920,
    qhd: 2560,
    uwqhd: 3440,
    uhd: 3840,
  },
};

export default breakpoints;
