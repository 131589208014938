"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.USER_TYPE_SAMPLE = exports.USER_TYPE_RENEWAL = exports.USER_TYPE_PARTNERSHIP = exports.USER_TYPE_INITIAL = void 0;
var USER_TYPE_PARTNERSHIP = 'partnerships';
exports.USER_TYPE_PARTNERSHIP = USER_TYPE_PARTNERSHIP;
var USER_TYPE_INITIAL = 'prepaid';
exports.USER_TYPE_INITIAL = USER_TYPE_INITIAL;
var USER_TYPE_RENEWAL = 'renewal';
exports.USER_TYPE_RENEWAL = USER_TYPE_RENEWAL;
var USER_TYPE_SAMPLE = 'sample';
exports.USER_TYPE_SAMPLE = USER_TYPE_SAMPLE;