"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _ffReactClientSdk = require("@harnessio/ff-react-client-sdk");
var getNewCheckoutFlag = function getNewCheckoutFlag() {
  return (0, _ffReactClientSdk.useFeatureFlag)('DTC_NewCheckout', false);
};
var _default = getNewCheckoutFlag;
exports["default"] = _default;