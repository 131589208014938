"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var countryCodes = {
  GB: {
    'Parcel Force': {
      deliveryMinimum: 3,
      deliveryMaximum: 5
    },
    DHL: {
      deliveryMinimum: 3,
      deliveryMaximum: 5
    },
    UPS: {
      deliveryMinimum: 3,
      deliveryMaximum: 5
    }
  },
  XI: {
    'Parcel Force': {
      deliveryMinimum: 3,
      deliveryMaximum: 5
    },
    DHL: {
      deliveryMinimum: 3,
      deliveryMaximum: 5
    },
    UPS: {
      deliveryMinimum: 3,
      deliveryMaximum: 5
    }
  }
};
var _default = countryCodes;
exports["default"] = _default;