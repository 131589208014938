"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SUBSCRIPTION_ACTIVE_KEY = exports.SKU_NAME_KEY = exports.FIRST_SIGNUP_DATE = exports.BRAZE_LANDING_PAGE_SLUGS = exports.BRAZE_BLOG_TAGS = exports.BRAZE_BLOG_SLUGS = exports.BRAZE_BLOG_CATEGORIES = exports.BRAZE_BENEFITS_ANSWERS = void 0;
var SKU_NAME_KEY = 'sku_name';
exports.SKU_NAME_KEY = SKU_NAME_KEY;
var SUBSCRIPTION_ACTIVE_KEY = 'subscription_active';
exports.SUBSCRIPTION_ACTIVE_KEY = SUBSCRIPTION_ACTIVE_KEY;
var FIRST_SIGNUP_DATE = 'first_signup_date';
exports.FIRST_SIGNUP_DATE = FIRST_SIGNUP_DATE;
var BRAZE_BLOG_SLUGS = 'blog_slugs';
exports.BRAZE_BLOG_SLUGS = BRAZE_BLOG_SLUGS;
var BRAZE_BLOG_CATEGORIES = 'blog_categories';
exports.BRAZE_BLOG_CATEGORIES = BRAZE_BLOG_CATEGORIES;
var BRAZE_BLOG_TAGS = 'blog_tags';
exports.BRAZE_BLOG_TAGS = BRAZE_BLOG_TAGS;
var BRAZE_BENEFITS_ANSWERS = 'benefits_quiz';
exports.BRAZE_BENEFITS_ANSWERS = BRAZE_BENEFITS_ANSWERS;
var BRAZE_LANDING_PAGE_SLUGS = 'page_slugs';
exports.BRAZE_LANDING_PAGE_SLUGS = BRAZE_LANDING_PAGE_SLUGS;